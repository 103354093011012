import React from "react"
import Navbar from "./components/Navbar"
import MyAccount from "./pages/MyAccount";
import Footer from "./components/Footer";
import {useEffect} from "react"
import axios, { Axios } from 'axios'


import Courses from "./pages/Courses";
import Main from "./pages/Main";
import Quiz from "./pages/newQuiz/Quiz";
import Quiz2 from "./pages/newQuizHSK2/Quiz";
import Quiz3 from "./pages/newQuizHSK3/Quiz";
import Quiz4 from "./pages/newQuizHSK4/Quiz";
import Quiz5 from "./pages/newQuizHSK5/Quiz";
import List from "./pages/FoldersHSKwords/List";
import ListHSK2 from "./pages/FoldersHSKwords/ListHSK2";
import ListHSK3 from "./pages/FoldersHSKwords/ListHSK3folders";
import ListHSK4 from "./pages/FoldersHSKwords/ListHSK4folders";
import ListHSK5 from "./pages/FoldersHSKwords/ListHSK5folders";

import ListHSK3SendingLimits from "./pages/ListHSK3/ListHSK3SendingLimits";
import ListHSK4SendingLimits from "./pages/ListHSK4/ListHSK4SendingLimits";
import ListHSK5SendingLimits from "./pages/ListHSK5/ListHSK5SendingLimits";

import JoinChat from "./pages/JoinChat";
import Register from "./pages/Register";
import Settings from "./pages/Settings";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import ChangeEmailSettings from "./pages/ChangeEmail";
import ChangePasswordSettings from "./pages/ChangePassword";
import RestorePassword from "./pages/RestorePassword";
import RestorePasswordRequest from "./pages/RestorePasswordRequest";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Paypal from "./pages/Paypal";
import RepeatQuiz from "./pages/newQuiz/RepeatQuiz";
import RepeatQuiz2 from "./pages/newQuizHSK2/RepeatQuiz"
import RepeatQuiz3 from "./pages/newQuizHSK3/RepeatQuiz"
import RepeatQuiz4 from "./pages/newQuizHSK4/RepeatQuiz"
import RepeatQuiz5 from "./pages/newQuizHSK5/RepeatQuiz"

import PayingQuestion from "./pages/PayingQuestionFolder/PayingQuestion";
import PayingQuestionHSK2 from "./pages/PayingQuestionFolder/PayingQuestionHSK2";
import PayingQuestionHSK3 from "./pages/PayingQuestionFolder/PayingQuestionHSK3";
import PayingQuestionHSK4 from "./pages/PayingQuestionFolder/PayingQuestionHSK4";
import PayingQuestionHSK5 from "./pages/PayingQuestionFolder/PayingQuestionHSK5";

import PayingQuestionRest from "./pages/PayingQuestionRest";
import PayedInfo from "./pages/PayedInfo";
import PayedInfo2 from "./pages/PayedInfo2";

import PaypalCheckout from "./pages/PaypalCheckoutsFolder/PaypalCheckout";
import PaypalCheckoutHSK2 from "./pages/PaypalCheckoutsFolder/PaypalCheckoutHSK2";
import PaypalCheckoutHSK3 from "./pages/PaypalCheckoutsFolder/PaypalCheckoutHSK3";
import PaypalCheckoutHSK4 from "./pages/PaypalCheckoutsFolder/PaypalCheckoutHSK4";
import PaypalCheckoutHSK5 from "./pages/PaypalCheckoutsFolder/PaypalCheckoutHSK5";

import Copyright from "./pages/Copyright";
import NotFoundPage from "./pages/NotFoundPage";

import { BrowserRouter, Routes, Route, Navigate, useLocation  } from "react-router-dom";

import { useState } from "react";

import { CodeProvider } from "./context/Context";

import { FriendNameProvider } from "./context/ContextFriendName";

const MyComponent = ({loginStatus, setLoginStatus}) => {

  const location = useLocation();
  
  const [isPaid, setIsPaid] = useState(3)

  const [isPaidHSK1, setIsPaidHSK1] = useState(3)
  const [isPaidHSK2, setIsPaidHSK2] = useState(3)
  const [isPaidHSK3, setIsPaidHSK3] = useState(3)
  const [isPaidHSK4, setIsPaidHSK4] = useState(3)
  const [isPaidHSK5, setIsPaidHSK5] = useState(3)

  useEffect(() => {
    //console.log("loginStatus", loginStatus)
    axios.get(`${process.env.REACT_APP_BACKEND}/login`).then((response) =>{
      setLoginStatus(response.data.loggedIn)
      //console.log(response.data.loggedIn)
      //console.log(typeof response.data.loggedIn)
    })

  }, [location.search])

  const [loggedInWithGoogle, setLoggedInWithGoogle] = useState(3)

  useEffect(() => {
    const isGoogleLoggedIn = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/isLoggedInWithGoogle`);
        if (response.data.loggedInWay === 'usualLogin') {
          setLoggedInWithGoogle(1);
        } else if (response.data.loggedInWay === 'googleLoggedIn') {
          setLoggedInWithGoogle(2);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        if (error.response) {
          console.error('Server responded with:', error.response.data);
        }
      }
    };
    isGoogleLoggedIn()
}, [location.search]);

useEffect(() => {
  const is_paid = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid_generally`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaid(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaid(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paid()
}, [location.search]);

useEffect(() => {
  const is_paidHSK1 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaidHSK1(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaidHSK1(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paidHSK1()

  const is_paidHSK2 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk2'}`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaidHSK2(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaidHSK2(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paidHSK2()

  const is_paidHSK3 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk3'}`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaidHSK3(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaidHSK3(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paidHSK3()


  const is_paidHSK4 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk4'}`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaidHSK4(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaidHSK4(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paidHSK4()

  const is_paidHSK5 = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid?hsk=${'_hsk5'}`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaidHSK5(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaidHSK5(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paidHSK5()

}, [location.search]);


  
  const authenticate = (route) => {
    //console.log("Current path:", location.pathname);
    //console.log("Login status inside authenticate:", loginStatus);
    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        return route
    }
  }

  const authenticate2 = (route) => {
    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        return <Main/>
    }
  }

  const authenticate3 = (route) => {
    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaid === 2){       
          return <Navigate to="/payingPage" replace />;
        }else{
          return route
        }
    }
  }

  const authenticate4 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK1 === 2){
          return route
        }else if(isPaidHSK1 === 1){
          return <Navigate to="/payed" replace />;
        }
    }
  }

  const authenticate4HSK2 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK2 === 2){
          return route
        }else if(isPaidHSK2 === 1){
          return <Navigate to="/payed" replace />;
        }
    }
  }

  const authenticate4HSK3 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK3 === 2){
          return route
        }else if(isPaidHSK3 === 1){
          return <Navigate to="/payed" replace />;
        }
    }
  }

  const authenticate4HSK4 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK4 === 2){
          return route
        }else if(isPaidHSK4 === 1){
          return <Navigate to="/payed" replace />;
        }
    }
  }

  const authenticate4HSK5 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK5 === 2){
          return route
        }else if(isPaidHSK5 === 1){
          return <Navigate to="/payed" replace />;
        }
    }
  }

  const authenticate5 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (loggedInWithGoogle === 2){
          return <Navigate to="loggedInWithGoogle" replace />;
        }else{
          return route
        }
    }
  }

  const authenticate6 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK1 === 2){
          return route
        }else if(isPaidHSK1 === 1){
          return <Navigate to="/payedInfo" replace />;
        }
    }
  }

  const authenticateHSK2 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK2 === 2){
          return route
        }else if(isPaidHSK2 === 1){
          return <Navigate to="/payedInfo" replace />;
        }
    }
  }

  const authenticateHSK3 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK3 === 2){
          return route
        }else if(isPaidHSK3 === 1){
          return <Navigate to="/payedInfo" replace />;
        }
    }
  }

  const authenticateHSK4 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK4 === 2){
          return route
        }else if(isPaidHSK4 === 1){
          return <Navigate to="/payedInfo" replace />;
        }
    }
  }

  const authenticateHSK5 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaidHSK5 === 2){
          return route
        }else if(isPaidHSK5 === 1){
          return <Navigate to="/payedInfo" replace />;
        }
    }
  }

  return (
    <div className="totalContainer">
      <Navbar/>
      <Routes>
        <Route path="/register" element={authenticate2(<Register/>)} />
        <Route path="/" element={authenticate(<Main/>)} />
        <Route path="/courses"  element={authenticate(<Courses/>)} />
        <Route path="/account" element={authenticate(<MyAccount/>)} />
        <Route path="/joinchat" element={authenticate3(<JoinChat/>)} />
        <Route path="/quiz" element={authenticate(<Quiz/>)} />
        <Route path="/quizHSK2" element={authenticate(<Quiz2/>)} />
        <Route path="/quizHSK3" element={authenticate(<Quiz3/>)} />
        <Route path="/quizHSK4" element={authenticate(<Quiz4/>)} />
        <Route path="/quizHSK5" element={authenticate(<Quiz5/>)} />
        <Route path="/list" element={authenticate(<List/>)} />
        <Route path="/listHSK2" element={authenticate(<ListHSK2/>)} />
        <Route path="/listHSK3" element={authenticate(<ListHSK3/>)} />
        <Route path="/listHSK4" element={authenticate(<ListHSK4/>)} />
        <Route path="/listHSK5" element={authenticate(<ListHSK5/>)} />

        <Route path="/listHSK3Words" element={authenticate(<ListHSK3SendingLimits/>)} />
        <Route path="/listHSK4Words" element={authenticate(<ListHSK4SendingLimits/>)} />
        <Route path="/listHSK5Words" element={authenticate(<ListHSK5SendingLimits/>)} />
    
        <Route path="/settings" element={authenticate(<Settings/>)} />
        <Route path="/changeEmail" element={authenticate5(<ChangeEmailSettings/>)} />
        <Route path="/changePassword" element={authenticate5(<ChangePasswordSettings/>)} />
        <Route path="/verifyemail" element={<VerifyEmailPage/>}/>
        <Route path="/restorepassword" element={<RestorePassword/>}/>
        <Route path="/restorepasswordrequest" element={<RestorePasswordRequest/>}/>             
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/paypal" element={authenticate(<Paypal/>)} />

        <Route path="/repeatQuiz" element={authenticate(<RepeatQuiz/>)} />
        <Route path="/repeatQuizHSK2" element={authenticate(<RepeatQuiz2/>)} />
        <Route path="/repeatQuizHSK3" element={authenticate(<RepeatQuiz3/>)} />
        <Route path="/repeatQuizHSK4" element={authenticate(<RepeatQuiz4/>)} />
        <Route path="/repeatQuizHSK5" element={authenticate(<RepeatQuiz5/>)} />

        <Route path="/paying" element={authenticate6(<PayingQuestion/>)} />
        <Route path="/payingPage" element={authenticate6(<PayingQuestionRest/>)} />
        <Route path="/paypalPayment" element={authenticate4(<PaypalCheckout/>)} />
        <Route path="/payedInfo" element={authenticate(<PayedInfo2/>)} />

        <Route path="/payingHSK2" element={authenticateHSK2(<PayingQuestionHSK2/>)} />
        <Route path="/paypalPaymentHSK2" element={authenticate4HSK2(<PaypalCheckoutHSK2/>)} />

        <Route path="/payingHSK3" element={authenticateHSK3(<PayingQuestionHSK3/>)} />
        <Route path="/paypalPaymentHSK3" element={authenticate4HSK3(<PaypalCheckoutHSK3/>)} />

        <Route path="/payingHSK4" element={authenticateHSK4(<PayingQuestionHSK4/>)} />
        <Route path="/paypalPaymentHSK4" element={authenticate4HSK4(<PaypalCheckoutHSK4/>)} />

        <Route path="/payingHSK5" element={authenticateHSK5(<PayingQuestionHSK5/>)} />
        <Route path="/paypalPaymentHSK5" element={authenticate4HSK5(<PaypalCheckoutHSK5/>)} />

        <Route path="/copyrightInfo" element={authenticate(<Copyright/>)} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer/>
    </div>
  )
}

export default function App() {

  const[loginStatus, setLoginStatus] = useState(undefined)
  
    return (
          <div>
            <FriendNameProvider>
              <CodeProvider>
                <BrowserRouter>
                  <MyComponent loginStatus={loginStatus} setLoginStatus={setLoginStatus}/>
                </BrowserRouter>
                </CodeProvider>
                </FriendNameProvider>
          </div>
    )
}