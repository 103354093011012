
import React, {useEffect, useState} from "react"
import axios from 'axios'

const QuestionRadical = ({question, checkAnswer, highlightedIndex, displayHint3}) => {

    //console.log('DISPLAY HINT3', displayHint3)
    //console.log('highlightedIndex', highlightedIndex)

    const myCorrect = highlightedIndex < question.answers.length && highlightedIndex !== -1 ? 
        question.answers[highlightedIndex].correct : false
    
    const [hintVisability, setHintVisability] = useState('')

    const getSettingsInfoFunction =() => {
        const url = `${process.env.REACT_APP_BACKEND}/getSettingsInfoHintDisplay`;
        axios.get(url).then((response) => {
                setHintVisability(response.data.hint)
        });
    }

    useEffect(() => {
        //alert(JSON.stringify(question))
        getSettingsInfoFunction()
      }, []);

      const answerWrong = {correct: false, answer: ''}

    return (
        <>
            <p className="word">{question.title}</p>
            <div className="testBlock">
                <p className="testRadical">{question.hint1} </p> 
                <p className="radicalsInformation">{myCorrect && question.swappable ? question.hint3 : question.hint2}</p>
            </div>
            <p className="testRadicalDescription">{hintVisability === 'Yes' ? question.info: ""}</p>
            <div className="space">

                {
                    question.answers.map((answer, index) => {
                        return (
                            <div className="boxes" key={index}>

                            <button 
                            onClick={() => {checkAnswer(answer, index, undefined, undefined, question.radical_index, question.character)}}
                            className = {index === highlightedIndex ? answer.correct ? "specialBox" : "specialBox2" : "box"}
                            >{answer.answer}
                            </button>
                            </div>
                        )
                    })
                                    
                }
            </div>
            <button className="button" onClick={() => {checkAnswer(answerWrong)}}>Forgot</button>
        </>
    )
}

const QuestionFreetype = ({question, checkAnswer, highlightedIndex}) => {
    const [inputChange, setInputChange] = useState('')
    const answerWrong = {correct: false, answer: ''}
    return (
        <>
            
            <p className="instructionToType">Type the word</p>
            <p className="word">{question.title}</p>
                    
            {question.answers.map((answer, index) => {
                return (
                    <div key={index}>
                    <form className="formForQuizSubmit" onSubmit={(event) => {  
                        event.preventDefault(); // Prevent default form submission behavior
                             }}>
                        <label>
                            <input 
                                type="text" 
            
                                className = {index === highlightedIndex ? answer.answer === inputChange ? "specialBox" : "specialBox2" : "box"}
                                value={inputChange} 
                                onChange={e => setInputChange(e.target.value)} 
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        event.preventDefault(); // Prevent default form submission behavior
                                        //console.log('inputChange', inputChange)
                                        //console.log('type of inputChange', typeof(inputChange))
                                        if (inputChange === ''){
                                            const emptyInput = 'wrong word in hsk'
                                            //console.log('emptyInput', emptyInput)
                                            checkAnswer(answer, index, inputChange, emptyInput); // Call your checkAnswer function here
                                            setTimeout(() => {
                                                setInputChange('');
                                            }, 1300)
                                        } else{
                                            checkAnswer(answer, index, inputChange); // Call your checkAnswer function here
                                            setTimeout(() => {
                                                setInputChange('');
                                            }, 1300)
                                        }   
                                    }
                                }}
                            />
                        </label>
                        <button className="buttonSubmitInputQuiz" onClick={() => {
                            if (inputChange === ''){
                                const emptyInput = 'wrong word in hsk'
                                checkAnswer(answer, index, inputChange, emptyInput); // Call your checkAnswer function here
                                setTimeout(() => {
                                    setInputChange('');
                                }, 1300)
                            } else{
                                checkAnswer(answer, index, inputChange); // Call your checkAnswer function here
                                setTimeout(() => {
                                    setInputChange('');
                                }, 1300)
                            }  
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="20px" height="20px" viewBox="0 0 56 56"><path d="M 13.8320 43.5625 C 14.4883 43.5625 14.9336 43.3516 15.4258 43.1172 L 41.7695 31.2813 C 43.1055 30.6484 44.1602 29.6172 44.1602 28.1172 C 44.1602 26.6406 43.1289 25.5625 41.7461 24.9532 L 15.4258 12.8359 C 14.9570 12.6016 14.5351 12.4375 13.9258 12.4375 C 12.7070 12.4375 11.8398 13.2813 11.8398 14.5235 C 11.8398 15.6016 12.4023 16.2110 13.3867 16.6797 L 38.6055 27.8125 L 38.6055 28.0703 L 13.3867 39.2969 C 12.4023 39.7656 11.8398 40.3750 11.8398 41.4531 C 11.8398 42.7422 12.6836 43.5625 13.8320 43.5625 Z"/>
                            </svg>
                        </button>
                    </form>
                    </div>
                )
            })}
                        
            <button className="button" onClick={() => {checkAnswer(answerWrong)}}>Forgot</button>
        </>
    )
}

const QuestionCharacter = ({question, checkAnswer, highlightedIndex}) => {
    const answerWrong = {correct: false, answer: ''}
    const text = question.character
    return (
        <>

            
                <p className="word">{question.title}</p>
                <div className="space">

                {
                    question.answers.map((answer, index) => {
                        return (
                            <div className="boxes" key={index}>
                                    <button 
                                    onClick={() => {checkAnswer(answer, index)}}
                                    className = {index === highlightedIndex ? answer.correct ? "specialBox" : "specialBox2" : "box"}
                                >{answer.answer}
                                    </button>
                            </div>

                        )
                    })
                                    
                }
            </div>
            <button className="button" onClick={() => {checkAnswer(answerWrong)}}>Forgot</button>
        </>
    )
}

export default function RepeatQuestion({question, checkAnswer, highlightedIndex, currentScore, maxScore}) {

    return (
        <>
            <div className="resultsBox"><div className="resultsShowing" style={{"width": `${(currentScore / maxScore) * 800}px`}}></div></div>

            {
                question.questionType === 'radical' ?
                    <QuestionRadical checkAnswer={checkAnswer} question={question} highlightedIndex={highlightedIndex}/>
                : question.questionType === 'character' ? 
                    <QuestionCharacter checkAnswer={checkAnswer} question={question} highlightedIndex={highlightedIndex}/>
                : question.questionType === 'freetype' ?
                    <QuestionFreetype checkAnswer={checkAnswer} question={question} highlightedIndex={highlightedIndex}/>
                : <>DEFAULT<br/></>
            }
        </>
        
    )
    
}