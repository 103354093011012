import React, { useState, useEffect } from "react";

const TextToSpeech = ({ text }) => {
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(null);
  const pitch = 1;
  const rate = 0.8;
  const volume = 1;

  useEffect(() => {
    let synth = window.speechSynthesis;

    const handleVoicesChanged = () => {
      const voices = synth.getVoices();
      let selectedVoice = null;

      // 🎯 Step 1: Prioritize Google voice first
      selectedVoice = voices.find(v => v.name.includes("Google") && v.name.includes("普通话") && v.lang === "zh-CN");

      // 🎯 Step 2: If Google voice is not available, try Microsoft Xiaoxiao
      if (!selectedVoice) {
        selectedVoice = voices.find(v => 
          v.name === "Microsoft Xiaoxiao Online (Natural) - Chinese (Mainland)" && v.lang === "zh-CN"
        );
      }

      // 🎯 Step 3: If still not found, try another Microsoft `zh-CN` voice
      if (!selectedVoice) {
        selectedVoice = voices.find(v => 
          v.name === "Microsoft Xiaoxiao Online (Natural) - zh-CN" && v.lang === "zh-CN"
        );
      }

      // 🎯 Step 4: If no preferred voices are found, pick any available `zh-CN` voice
      if (!selectedVoice) {
        selectedVoice = voices.find(v => v.lang.includes("zh-CN"));
      }

      // 🎯 Step 5: If still no voice, use the first available one (Android fix)
      if (!selectedVoice && voices.length > 0) {
        selectedVoice = voices[0];
      }

      // 🎯 Step 6: Set the selected voice and create utterance
      if (selectedVoice) {
        setVoice(selectedVoice);
        setUtterance(new SpeechSynthesisUtterance(text));
        //console.log("✅ Selected Voice:", selectedVoice.name);
      } else {
        //console.warn("❌ No valid Chinese voice found!");
      }
    };

    // 🎯 Ensure voices are loaded before selecting one (important for Android)
    setTimeout(() => {
      if (synth.getVoices().length > 0) {
        handleVoicesChanged();
      } else {
        synth.addEventListener("voiceschanged", handleVoicesChanged);
        setTimeout(handleVoicesChanged, 1500); // Extra delay for Android voice loading
      }
    }, 300); // Small delay to ensure voices are available

    return () => {
      synth.cancel();
      synth.removeEventListener("voiceschanged", handleVoicesChanged);
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (!utterance || !voice) {
      //console.error("❌ No valid voice selected.");
      return;
    }

    // 🎯 Ensure correct language so Android doesn't switch to Russian
    utterance.voice = voice;
    utterance.lang = "zh-CN";
    utterance.pitch = pitch;
    utterance.rate = rate;
    utterance.volume = volume;

    //console.log("🎤 Speaking with:", voice.name, "| Language:", voice.lang);

    synth.speak(utterance);
  };

  return (
      <div className="audioSVG" onClick={handlePlay}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#808080" width="60px" height="60px" viewBox="-1.5 0 19 19" className="cf-icon-svg"><path d="M7.098 4.769v9.63c0 .61-.353.756-.784.325L3.42 11.828H1.442A1.112 1.112 0 0 1 .333 10.72V8.448A1.112 1.112 0 0 1 1.442 7.34h1.977l2.895-2.896c.431-.43.784-.285.784.325zm2.076 7.474a.553.553 0 0 0 .392-.163 3.53 3.53 0 0 0 0-4.992.554.554 0 1 0-.784.784 2.422 2.422 0 0 1 0 3.425.554.554 0 0 0 .392.946zm2.184 1.629a6.059 6.059 0 0 0 0-8.575.554.554 0 0 0-.784.783 4.955 4.955 0 0 1 0 7.008.554.554 0 0 0 .784.784zm1.79 1.79a8.59 8.59 0 0 0 0-12.157.554.554 0 0 0-.783.784 7.481 7.481 0 0 1 0 10.59.554.554 0 1 0 .784.784z"/></svg>
      </div>
  );
};

export default TextToSpeech;
