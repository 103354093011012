import React, { useEffect, useState } from "react"
import axios from 'axios'

import RepeatQuestion from "./RepeatQuestion"
import Description from "./Description"

const QuizOver = ({characters, isRemoving}) => {
    const [arrayOfChosenCharacters, setArrayOfChosenCharacters] = useState([]);
    const [arrayOfChosenCharactersRepeat, setArrayOfChosenCharactersRepeat] = useState([]);

    const learnAgainFunction =(character, isRemoving) => {

        axios.post(`${process.env.REACT_APP_BACKEND}/learnAgainFunction`, {
            character: character,
            hsk:'_hsk4'
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            setArrayOfChosenCharacters(prevArray => [...prevArray, response.data.restarted]);
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }

    const repeatAgainFunction =(character) => {

        //console.log('character', character)

        axios.post(`${process.env.REACT_APP_BACKEND}/repeatAgainFunction`, {
            character: character,
            hsk:'_hsk4'
        }).then((response) => {
            //console.log('Response data', response.data.restarted)
            setArrayOfChosenCharactersRepeat(prevArray => [...prevArray, response.data.restarted]);
        }).catch((err) => {
            console.log(JSON.stringify(err.response.data.restarted))
        })   
    }

    const url = window.location.pathname;
    const linkName = url.substring(url.lastIndexOf('/') + 1);

    const [completedRepeating, setCompletedRepeating] = useState(0)
    const [loading, setLoading] = useState(0)

     useEffect(() => {
            if (!isRemoving) {
                repeatedOver()
            }
        }, [isRemoving])

        const repeatedOver =() => {

            axios.get(`${process.env.REACT_APP_BACKEND}/isRepeatingOver?hsk=${'_hsk4'}`).then((response) => {
                //setPost(response.data);
                if (response.data.completed){
                    setCompletedRepeating(response.data.completed)
                    //console.log('the result is', response.data.completed)
                    setLoading(1)
                } 
            });
            
        }

    return (
        <>
            <div className="congratulationsBlockDiv">
                <div className="congratulationsBlock">
                    YOU REPEATED THIS PART
                    <br/>
                    <h2>:)</h2>
                    <p className="greetingWord">Congratulations!</p>

                    
                    { loading === 0 ?
                    <a href={undefined}>
                        <p className="buttonBackToTest"></p>
                    </a> 
                    :
                    (completedRepeating === 1 ? 
                       <a href="/courses">
                            <p className="buttonBackToTest">Back to tests</p>
                        </a>
                        :
                        <a href="/repeatQuizHSK4">
                            <p className="buttonBackToTest">Continue repeating</p>
                        </a>
                     )
                    }
                   
                </div>
                <div className="learnedWords">
                    
                    <div className="learnedWordsText">Words you were studying:</div>
        
                    {
                        characters && characters.length > 0 ? (
                                characters.map((character, index) => {
                                    return (
                                        <div className="descriptionListCongrPage" key={index}>
                                        <p className="mainWordList">{character.character}</p>
                                        <p className="translationWordList">{character.translation}</p>
                                        <p className="radicalsDescriptionList">{character.hintsRadicals}</p>
                                        <p className="radicalsTranslationList">{character.hintsRadicalTranslations}</p>
                                        <p className="wordDescriptionListCongrPage">{character.info}</p>
                                        <br/>
                                        <div className="svgBlockCongrPage">
                                        {
                                            linkName === "quiz" ? null : 
                                            <div className="divForRepeatWordSvg">
                                            <svg className={linkName === "repeatQuizHSK4" ? (arrayOfChosenCharactersRepeat.includes(`${character.character} restarted`) ? "repeatWordSvgCursor": "repeatWordSvg2") : "repeatWordSvg"} xmlns="http://www.w3.org/2000/svg" onClick={() => {repeatAgainFunction(character.character)}} width="24px" height="24px" viewBox="0 0 16 16">
                                            <path fill="#000000" d="M5.23331,0.493645 C6.8801,-0.113331 8.6808,-0.161915 10.3579,0.355379 C11.5179,0.713177 12.5743,1.32796 13.4526,2.14597 L14.2929,1.30564 C14.9229,0.675676 16,1.12184 16,2.01275 L16,6.00002 L12.0127,6.00002 C11.1218,6.00002 10.6757,4.92288 11.3056,4.29291 L12.0372,3.56137 C11.389,2.97184 10.6156,2.52782 9.76845,2.26653 C8.5106,1.87856 7.16008,1.915 5.92498,2.37023 C4.68989,2.82547 3.63877,3.67423 2.93361,4.78573 C2.22844,5.89723 1.90836,7.20978 2.02268,8.52112 C2.13701,9.83246 2.6794,11.0698 3.56627,12.0425 C4.45315,13.0152 5.63528,13.6693 6.93052,13.9039 C8.22576,14.1385 9.56221,13.9407 10.7339,13.3409 C11.9057,12.7412 12.8476,11.7727 13.4147,10.5848 C13.6526,10.0864 14.2495,9.8752 14.748,10.1131 C15.2464,10.351 15.4575,10.948 15.2196,11.4464 C14.4635,13.0302 13.2076,14.3215 11.6453,15.1213 C10.0829,15.921 8.30101,16.1847 6.57402,15.8719 C4.84704,15.559 3.27086,14.687 2.08836,13.39 C0.905861,12.0931 0.182675,10.4433 0.0302394,8.69483 C-0.122195,6.94637 0.304581,5.1963 1.2448,3.7143 C2.18503,2.2323 3.58652,1.10062 5.23331,0.493645 Z"/>
                                            </svg>
                                            <div className="repeatWordText">repeat the word</div>
                                            </div>
                                        }
                                        <div className="divForLearnWordSvg">
                                        <svg className={linkName === "repeatQuizHSK4" ? (arrayOfChosenCharacters.includes(`${character.character} restarted`) ? "learnAgainSvg2Cursor": "learnAgainSvg") : "learnAgainSvg2"} onClick={() => {learnAgainFunction(character.character)}} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 -8.14 62.451 62.451">
                                        <g id="Group_35" data-name="Group 35" transform="translate(-782.964 -1356.609)">
                                            <path id="Path_92" data-name="Path 92" d="M798.022,1369.359v23.226h.034c.353,4.555,7.685,8.2,16.7,8.2s16.347-3.641,16.7-8.2h.033v-23.226Z" fill="#d1d3d4" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                                            <path id="Path_93" data-name="Path 93" d="M843.415,1373.207l-29.225,14.6-29.227-14.6,29.227-14.6Z" fill="#ffffff" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                                            <line id="Line_40" data-name="Line 40" y1="19.235" transform="translate(784.964 1374.361)" fill="#ffffff" stroke="#231f20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
                                        </g>
                                        </svg>
                                        <div className="learnWordTextQuiz">learn the word again</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={arrayOfChosenCharacters.includes(`${character.character} restarted`) ? (linkName === "repeatQuizHSK4" ? "checkDoneList" : "checkDoneList2") : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" className={arrayOfChosenCharactersRepeat.includes(`${character.character} restarted`) ? (linkName === "repeatQuizHSK4" ? "checkDoneListRepeat" : "checkDoneList2Repeat") : "notDisplayed"} width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="green"/>
                                        </svg>
                                        </div>
                        </div>
                                    )
                                })  ) : (
                                    <div>Nothing here</div>
                                )
                                                
                    }
                </div>
            </div>
        </>
    )
}

export default function RepeatQuiz() {

    const [questions, setQuestions] = useState(undefined)
    const [characters, setCharacters] = useState(undefined)
    const [list, setList] = useState(undefined)
    const [isAnswering, setIsAnswering] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(-1)
    const [characterDisplayList, setCharacterDisplayList] = useState([]) // description list
    const [visitedCharacters, setVisitedCharacters] = useState([])
    const [maxScore, setMaxScore] = useState(0)
    const [currentScore, setCurrentScore] = useState(0)
    const [displayHint3, setDisplayHint3] = useState(0)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        const fetchQuiz = async () => {
            axios.get(`${process.env.REACT_APP_BACKEND}/quizHSK4/getQuizRepeat`).then(res => {
                // alert(JSON.stringify(res.data.questions[0]))
                setQuestions(res.data.questions)
                setList(res.data.list)
                setCharacters(res.data.characters)
                setMaxScore(res.data.questions.length)
                setInitialized(true)
                //console.log(res.data.list)
            })
        }

        fetchQuiz()
    }, [])

    const [isRemoving, setIsRemoving] = useState(false);

    const removeQuestion =(radical_index) => {

        if (questions && questions.length === 1){
            setIsRemoving(true);
        }
        //console.log('the questions is', questions[0].questionType)
        const temp_questions = [...questions]
        const first = temp_questions.shift()
        //console.log('info repeat', first.character, first.questionType)
        if(first) {
            //alert(JSON.stringify(first))
            axios.post(`${process.env.REACT_APP_BACKEND}/quizHSK4/answerRepeat`, {radical_index: radical_index, character: first.character, question_index: first.questionType}, {}).then((res) => {
                //alert("DONE")
                if (questions && questions.length === 1){
                    setIsRemoving(false);
                }
            }).catch(error => {
                alert(error.message)
            })
        }
        setQuestions(temp_questions)
        setCurrentScore(currentScore + 1)
        
    }

    const removeRadical =(character) => {

            axios.post(`${process.env.REACT_APP_BACKEND}/quiz/removeRadical`, {character: character}, {}).then((res) => {
                //alert("DONE")
            }).catch(error => {
                alert(error.message)
            })
        }

    const scrambleAnswers = () => {
        if(questions.length < 1)
            return

        const [first, ...rest] = questions
        //console.log(first.answers)
        first.answers.sort(() => Math.random() - 0.5)
        setQuestions([first, ...rest])
        

    }

    const swapQuestion = () => {
        if(questions.length < 2)
            return
        const [first, second, ...rest] = questions

        if(first.swappable === true) {
            first.swappable_attempts += 1
            if(first.swappable_attempts >= 2) {
                // move to back
                setQuestions([second, ...rest, first])
            } else {
                setQuestions([second, first, ...rest])
            }
        }else if(first.character !== second.character) {
            setQuestions([second, first, ...rest])
        }

        //if(first.swappable === true || first.character !== second.character) {
        //    setQuestions([second, first, ...rest])
        //}
        //if(first.character === second.character && first.swappable === true)
        //    setQuestions([second, first, ...rest])
    }

    const checkAnswer = (answer, index, input, emptyInput, radical_index, question_character) => {
        //console.log('answer is', answer)
        //console.log('index is', index)
        //console.log('radical_index', radical_index)
        //console.log('question_character', question_character)
        if(isAnswering === true)
            return; // already in progress
        setIsAnswering(true)
        setHighlightedIndex(index)
        //console.log('emptyInput', emptyInput)
        if(input || emptyInput){
               //console.log('input', input) 
               if(answer.answer === input && emptyInput != 'wrong word in hsk'){
                //console.log('answer is correct')
                setDisplayHint3(1)
                setTimeout(() => {
                    removeQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                    setDisplayHint3(0)
                }, 1300)
               }else{
                //console.log('answer is wrong')
                setTimeout(() => {
                    addCurrentCharacterDescription()
                    scrambleAnswers()
                    swapQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                }, 1300)
               }
        } else{
            if(answer.correct) {
                //console.log('answer is correct 2')
                setDisplayHint3(1)
                setTimeout(() => {
                    if(radical_index != undefined){
                        removeQuestion(radical_index)
                    } else {
                        removeQuestion()
                    }
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                    setDisplayHint3(0)
                }, 1300)
            } else {
                //console.log('answer is wrong 2')
                setTimeout(() => {
                    addCurrentCharacterDescription()
                    scrambleAnswers()
                    swapQuestion()
                    setIsAnswering(false)
                    setHighlightedIndex(-1)
                }, 1300)
            }
            
        }
        
    }


    const addCurrentCharacterDescription = () => {
        if(characters && questions && questions.length > 0) {

            const [...temp] = characterDisplayList
            for(let i=0;i<characters.length;i++) {
                if(characters[i].character === questions[0].character) {
                    temp.push(characters[i])
                    break
                }
            }
            setCharacterDisplayList(temp)
        }
        
    }

    const addNewDescription = () => {

        // Add description if current question hasn't displayed the description yet

        if(characters && questions && questions.length > 0) {

            for(let i=0;i<visitedCharacters.length;i++) {
                if(questions[0].character === visitedCharacters[i]) {
                    return
                }
            }

            for(let i=0;i<characters.length;i++) {
                if(characters[i].character === questions[0].character) {
                    const [...temp2] = visitedCharacters
                    const [...temp1] = characterDisplayList
                    temp2.push(questions[0].character)
                    temp1.push(characters[i])
                    setVisitedCharacters(temp2)
                    setCharacterDisplayList(temp1)
                }
            }

            
            
            
            
        }
    }

    // addNewDescription()

    const removeCurrentDescription = () => {
        const [...temp] = characterDisplayList
        temp.shift()
        setCharacterDisplayList(temp)
    }

    /*return (
        <div className="test">
            <QuizOver characters={list}/>
        </div>
    )*/

    return (
        <div className="test">
            {
            characterDisplayList.length > 0 ? 
                <Description character = {characterDisplayList[0]} remove={removeCurrentDescription}/>
                :
                (questions && questions.length > 0) ?
                    <RepeatQuestion checkAnswer={checkAnswer} question={questions[0]} highlightedIndex={highlightedIndex} displayHint3={displayHint3} currentScore={currentScore} maxScore={maxScore}/>
                    :
                    (initialized === false) ? 
                        <div className="main"></div>
                    :
                    <QuizOver characters={list} isRemoving={isRemoving}/>
            }
        </div>
    )

}